
@font-face {
  font-family: 'Ukraine';
  src:  url('./css/e-Ukraine-Regular.otf?v=1') format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ukraine';
  src:  url('./css/e-Ukraine-Bold.otf?v=1') format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Ukraine";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2D3C8B;
  color:'#fff'
 
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #fff;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #fff;
  }
  

  
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


#scanner-container {
  position: relative;
  overflow: hidden; 
  height:75vh;
  /* box-shadow: 0px 5px 5px #225ea8; */
}

.drop-up .Select-menu-outer {
  top: auto;
  bottom: 100%;
}

video{
  height:75vh;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  height:75vh;
}


